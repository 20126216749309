$.ajaxSetup ({
    // Disable caching of AJAX responses
    cache: false
});

function scroll2ErrorDiv(divElement) {
    $([document.documentElement, document.body]).animate({
        scrollTop: (divElement.offset().top - 250)
    }, 1000);

}

function fl_setAsDisableLike(obj) {
    if (obj.type === 'text' || obj.type === 'number' || obj.type === 'select-one') {
        $(obj).attr("readOnly", "true");
    }
}

function fl_removeDisableLike(obj) {
    if (obj.type === 'text' || obj.type === 'number' || obj.type === 'select-one') {
        $(obj).removeAttr("readOnly");
    }
}


function refreshModalContent(form, url) {
    //var _ser = $("#" + form + "").serialize();
    var _ser = customSerialize(form);
    //_ser = _ser.replace(/&/g, "*");
    var serializeValue = url + _ser;
    var encurl = getEncryptedUrlWithParameters(serializeValue);
    var _Modal_Src = $('#promptModal');
    _Modal_Src.modal('show').find('#modal-content').load(encurl);
    //modalReload(encurl);
}

var customSerialize = function (form){
    var url = '';
    $("#" + form + "").each(function(){
        $(this).find(':input').each(function () {
            if(this.disabled !== true){
                if(this.type === 'checkbox' || this.type === 'radio'){
                    if(this.checked){
                        url += '*'+ this.name + "=" +this.value;
                    }
                }else{
                    url += '*'+ this.name + "=" +this.value;
                }
            }
        });
    });
    return (url);
};


$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
    var _Modal_Src = $('#promptModal');
    $("[data-toggle='prompt-modal']").click(function (e) {
        e.preventDefault();
        _Modal_Src.modal('show').find('#modal-content').load(e.currentTarget.attributes["data-prompt-target"].nodeValue);
        //modalReload(e.currentTarget.attributes["data-prompt-target"].nodeValue);
    });
});

//ie'de calismadigi icin iptal edildi
var modalReload = function (url) {
    $('#base-footer').load(url, function (response, status, xhr) {
        $('#promptModal').modal('show').find('#modal-content').html("");
        if (status === "success") {
            $('#promptModal').modal('show').find('#modal-content').html(response);
        } else{
            $('#promptModal').modal('show').find('#modal-content').html(xhr.statusCode + " " + xhr.statusText + " url read error!s");
        }
    });
}

!function ($) {

    "use strict";

    var TabCollapse = function (el, options) {
        this.options   = options;
        this.$tabs  = $(el);

        this._accordionVisible = false;
        this._initAccordion();
        this._checkStateOnResize();
        var that = this;
        setTimeout(function() {
            that.checkState();
        }, 0);
    };

    TabCollapse.DEFAULTS = {
        accordionClass: 'visible-xs',
        tabsClass: 'hidden-xs',
        accordionTemplate: function(heading, groupId, parentId, active) {
            return  '<div class="panel panel-default">' +
                '   <div class="panel-heading">' +
                '      <h4 class="panel-title">' +
                '      </h4>' +
                '   </div>' +
                '   <div id="' + groupId + '" class="panel-collapse collapse ' + (active ? 'in' : '') + '">' +
                '       <div class="panel-body js-tabcollapse-panel-body">' +
                '       </div>' +
                '   </div>' +
                '</div>'

        }
    };

    TabCollapse.prototype.checkState = function(){
        if (this.$tabs.is(':visible') && this._accordionVisible){
            this.showTabs();
            this._accordionVisible = false;
        } else if (this.$accordion.is(':visible') && !this._accordionVisible){
            this.showAccordion();
            this._accordionVisible = true;
        }
    };

    TabCollapse.prototype.showTabs = function(){
        var view = this;
        this.$tabs.trigger($.Event('show-tabs.bs.tabcollapse'));

        var $panelHeadings = this.$accordion.find('.js-tabcollapse-panel-heading').detach();
        $panelHeadings.each(function() {
            var $panelHeading = $(this),
                $parentLi = $panelHeading.data('bs.tabcollapse.parentLi');
            view._panelHeadingToTabHeading($panelHeading);
            $parentLi.append($panelHeading);
        });

        var $panelBodies = this.$accordion.find('.js-tabcollapse-panel-body');
        $panelBodies.each(function(){
            var $panelBody = $(this),
                $tabPane = $panelBody.data('bs.tabcollapse.tabpane');
            $tabPane.append($panelBody.children('*').detach());
        });
        this.$accordion.html('');

        this.$tabs.trigger($.Event('shown-tabs.bs.tabcollapse'));
    };

    TabCollapse.prototype.showAccordion = function(){
        this.$tabs.trigger($.Event('show-accordion.bs.tabcollapse'));

        var $headings = this.$tabs.find('li:not(.dropdown) [data-toggle="tab"], li:not(.dropdown) [data-toggle="pill"]'),
            view = this;
        $headings.each(function(){
            var $heading = $(this),
                $parentLi = $heading.parent();
            $heading.data('bs.tabcollapse.parentLi', $parentLi);
            view.$accordion.append(view._createAccordionGroup(view.$accordion.attr('id'), $heading.detach()));
        });

        this.$tabs.trigger($.Event('shown-accordion.bs.tabcollapse'));
    };

    TabCollapse.prototype._panelHeadingToTabHeading = function($heading) {
        var href = $heading.attr('href').replace(/-collapse$/g, '');
        $heading.attr({
            'data-toggle': 'tab',
            'href': href,
            'data-parent': ''
        });
        return $heading;
    };

    TabCollapse.prototype._tabHeadingToPanelHeading = function($heading, groupId, parentId, active) {
        $heading.addClass('js-tabcollapse-panel-heading ' + (active ? '' : 'collapsed'));
        $heading.attr({
            'data-toggle': 'collapse',
            'data-parent': '#' + parentId,
            'href': '#' + groupId
        });
        return $heading;
    };

    TabCollapse.prototype._checkStateOnResize = function(){
        var view = this;
        $(window).resize(function(){
            clearTimeout(view._resizeTimeout);
            view._resizeTimeout = setTimeout(function(){
                view.checkState();
            }, 100);
        });
    };

    TabCollapse.prototype._initAccordion = function(){
        this.$accordion = $('<div class="panel-group ' + this.options.accordionClass + '" id="' + this.$tabs.attr('id') + '-accordion' +'"></div>');
        this.$tabs.after(this.$accordion);
        this.$tabs.addClass(this.options.tabsClass);
        this.$tabs.siblings('.tab-content').addClass(this.options.tabsClass);
    };

    TabCollapse.prototype._createAccordionGroup = function(parentId, $heading){
        var tabSelector = $heading.attr('data-target'),
            active = $heading.data('bs.tabcollapse.parentLi').is('.active');

        if (!tabSelector) {
            tabSelector = $heading.attr('href');
            tabSelector = tabSelector && tabSelector.replace(/.*(?=#[^\s]*$)/, ''); //strip for ie7
        }

        var $tabPane = $(tabSelector),
            groupId = $tabPane.attr('id') + '-collapse',
            $panel = $(this.options.accordionTemplate($heading, groupId, parentId, active));
        $panel.find('.panel-heading > .panel-title').append(this._tabHeadingToPanelHeading($heading, groupId, parentId, active));
        $panel.find('.panel-body').append($tabPane.children('*').detach())
            .data('bs.tabcollapse.tabpane', $tabPane);

        return $panel;
    };

    $.fn.tabCollapse = function (option) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('bs.tabcollapse');
            var options = $.extend({}, TabCollapse.DEFAULTS, $this.data(), typeof option === 'object' && option);

            if (!data) $this.data('bs.tabcollapse', new TabCollapse(this, options));
        });
    };

    $.fn.tabCollapse.Constructor = TabCollapse;


}(window.jQuery);

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

(function($) {


    var Defaults = $.fn.select2.amd.require('select2/defaults');

    $.extend(Defaults.defaults, {
        optAciklama: true
    });


    var SingleSelection = $.fn.select2.amd.require('select2/selection/single');

    var _updateSingleSelection = SingleSelection.prototype.update;

    SingleSelection.prototype.update = function(data) {

        _updateSingleSelection.apply(this, Array.prototype.slice.apply(arguments));

        var optAciklama = this.options.get('optAciklama');

        if (optAciklama === false) {
            var $rendered = this.$selection.find('.select2-selection__rendered');
            $rendered.removeAttr('title');
        }

    };

    var MultipleSelection = $.fn.select2.amd.require('select2/selection/multiple');

    var _updateMultipleSelection = MultipleSelection.prototype.update;

    MultipleSelection.prototype.update = function(data) {

        _updateMultipleSelection.apply(this, Array.prototype.slice.apply(arguments));

        var optAciklama = this.options.get('optAciklama');

        if (optAciklama === false) {
            var $rendered = this.$selection.find('.select2-selection__rendered');
            $rendered.find('.select2-selection__choice').removeAttr('title');
        }

    };

})(window.jQuery);

